import { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import reduce from 'lodash/reduce';
import _map from 'lodash/map';
import EventList from './event-list';
import { momentParse } from '../utils/time';

const dayFormat = 'dddd, MMMM D, YYYY';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    '& > h2': {
      marginTop: theme.spacing(2),
    },
  },
  groupHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  groupHeaderChip: {
    marginLeft: theme.spacing(1),
  },
}));

const DatedEventList = (props) => {
  const { events, allowPast } = props;
  const classes = useStyles();

  const now = useMemo(() => momentParse(), []);

  const groupedEvents = useMemo(() => {
    if (!events || !events.length) {
      return null;
    }

    return reduce(events, (acc, event) => {
      const { startAt: origStartAt, timezone } = event;

      let value;
      let title;

      if (!origStartAt) {
        value = 0;
        title = 'No Date';
      } else {
        let startAt = momentParse(origStartAt, timezone);
        if (!allowPast && startAt.isBefore(now)) {
          startAt = now;
        }

        value = moment(startAt).startOf('day').valueOf();
        title = startAt.format(dayFormat);
      }

      acc[value] = acc[value] || { value, title, events: [] };
      acc[value].events.push(event);
      return acc;
    }, {});
  }, [events, allowPast]);

  if (!events || !events.length) {
    return (
      <div className={classes.wrapper}>
        <Typography variant="h2">{allowPast ? 'Past' : 'Upcoming'} Events</Typography>

        <p>No {allowPast ? 'past' : 'upcoming'} events.</p>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      {_map(groupedEvents, ({ title, events }) => {
        if (!events) {
          return null;
        }

        let titleTag;
        let titleTagProps = {};
        const startAt = momentParse(events[0].startAt, events[0].timezone);
        if (startAt.isSame(now, 'day')) {
          titleTag = 'Today';
          titleTagProps = { color: 'primary' };
        }
        // if before end of Sunday this week
        else if (startAt.isSame(moment(now).add(1, 'day'), 'day')) {
          titleTag = 'Tomorrow';
          titleTagProps = { color: 'primary', variant: 'outlined' };
        }
        // if before end of Sunday this week
        else if (startAt.isSame(now, 'week')) {
          titleTag = 'This Week';
          titleTagProps = { color: 'primary', variant: 'outlined' };
        }
        // if before end of Sunday next week
        else if (startAt.isSame(moment(now).add(1, 'week'), 'week')) {
          titleTag = 'Next Week';
          titleTagProps = { variant: 'outlined' };
        }

        return (
          <Fragment key={title}>
            <Typography variant="h2" className={classes.groupHeader}>
              {title}
              {titleTag && (
                <Chip
                  label={titleTag}
                  size="small"
                  className={classes.groupHeaderChip}
                  {...titleTagProps}
                />
              )}
            </Typography>

            <EventList events={events} />
          </Fragment>
        );
      })}
    </div>
  )
};

DatedEventList.propTypes = {
  events: PropTypes.array,
  allowPast: PropTypes.bool,
};

DatedEventList.defaultProps = {
  allowPast: false,
};

export default DatedEventList;
