import { useState, useMemo, Fragment } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useQuery, useApolloClient } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _map from 'lodash/map';
import { useAppContext } from '../../contexts/app';
import Loading from '../../components/loading';
import Main from '../../components/main';
import ErrorMessage from '../../components/error-message';
import NotFound from '../../components/not-found';
import DatedEventList from '../../components/dated-event-list';
import EventList from '../../components/event-list';
import { cityEventsQuery, cityEventsPaginationQuery } from '../../apollo/cities';
import { buildTitle } from '../../utils/layout';
import config from '../../config';
import CalendarHeader from '../../components/calendar/header';
import LoadMore from '../../components/load-more';
import { filterableRoute } from '../../utils/routes';
import CalendarFooter from '../../components/calendar/footer';

const buildJsonLDBreadcrumbs = (city) => {
  const items = [
    {
      '@id': `${config().universal.baseUri}/cities/${city.slug}`,
      name: city.name,
    },
    {
      '@id': `${config().universal.baseUri}/calendar`,
      name: 'Calendar',
    },
  ];
  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: _map(items, (item, index) => ({
      "@type": "ListItem",
      position: index + 1,
      item
    }))
  };
};

const limit = 15;

const CalendarIndexPage = () => {
  const router = useRouter();
  const client = useApolloClient();
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const initialPage = router.query.page ? parseInt(router.query.page, 10) : 1;
  const [page, setPage] = useState(initialPage);
  const { city: appCity } = useAppContext();

  const filters = useMemo(() => (
    {
      ..._pick(router.query, ['filter', 'type', 'categoryIds']),
      categoryIds: router.query.categoryIds ? router.query.categoryIds.split(',') : null,
    }
  ), [router.query]);

  const cityQueryVariables = {
    id: _get(appCity, 'id'),
    filters,
    limit: limit * initialPage,
  };
  const { data: cityData, loading, error } = useQuery(cityEventsQuery, {
    variables: cityQueryVariables,
    skip: !appCity,
  });
  const city = appCity ? { ...appCity, ..._get(cityData, 'city') } : null;

  function handleLoadMore() {
    setLoadMoreLoading(true);
    client.query({
      query: cityEventsPaginationQuery,
      variables: {
        id: city.id,
        filters,
        offset: city.events.pageInfo.count,
        limit: limit
      }
    }).then(({ data }) => {
      try {
        const cachedCityData = client.readQuery({
          query: cityEventsQuery,
          variables: cityQueryVariables
        });
        client.writeQuery({
          query: cityEventsQuery,
          variables: cityQueryVariables,
          data: {
            ...cachedCityData,
            city: {
              ...cachedCityData.city,
              events: {
                ...cachedCityData.city.events,
                nodes: [
                  ...cachedCityData.city.events.nodes,
                  ...data.city.events.nodes
                ],
                pageInfo: {
                  ...data.city.events.pageInfo,
                  count: cachedCityData.city.events.pageInfo.count +
                    data.city.events.pageInfo.count
                }
              }
            }
          }
        });
      } catch (e) {
        // do nothing
      }
      setPage(page + 1);
      setLoadMoreLoading(false);
    }).catch((data) => {
      alert(data);
      setLoadMoreLoading(false);
    });
  }

  if (error) {
    return <ErrorMessage message='An error occurred while loading city.' error={error} />;
  }
  if (loading) { return <Loading />; }
  if (!city) { return <NotFound />; }

  const pageTitle = `${city.name} Cycling Calendar`;
  const canonicalUrl = filterableRoute(
    `${config().universal.baseUri}/calendar`,
    { ...filters, page: page === 1 ? null : page }
  );

  return (
    <Main signupBanner>
      <Head>
        <title>{buildTitle(pageTitle)}</title>
        <link rel="canonical" href={canonicalUrl} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(buildJsonLDBreadcrumbs(city))
          }}
        />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta
          property="og:description"
          content="Browse upcoming group rides and bike events, join local cycling groups, sign up for weekly notifications."
        />
        <meta
          name="description"
          content={`${city.name}'s community calendar of group rides and bike events. Browse upcoming events and join local cycling groups.`}
        />
        {page > 1 && (
          <link
            rel="prev"
            href={
              filterableRoute(
                `${config().universal.baseUri}/cities/${city.slug}`,
                { ...filters, page: page - 1 }
              )
            }
          />
        )}
        {city.events.pageInfo.hasNextPage && (
          <link
            rel="next"
            href={
              filterableRoute(
                `${config().universal.baseUri}/cities/${city.slug}`,
                { ...filters, page: page + 1 }
              )
            }
          />
        )}
      </Head>

      <CalendarHeader title="Calendar" tab="list" filters={filters} />

      {filters.filter === 'recent' ? (
        <Fragment>
          <Typography variant="h2">{filters.filter === 'recent' ? 'Recently Added' : 'Past'}</Typography>

          <EventList
            events={city.events.nodes}
            emptyMessage={`No ${filters.filter === 'recent' ? 'recently added' : 'past'} events`}
            fullDate
          />
        </Fragment>
      ) : (
        <DatedEventList
          events={city.events.nodes}
          city={city}
          allowPast={filters.filter === 'past'}
        />
      )}

      {city.events.pageInfo.hasNextPage && (
        <LoadMore
          onClick={handleLoadMore}
          loading={loadMoreLoading}
          link={filterableRoute(`/cities/${city.slug}`, { ...filters, page: page + 1 })}
        />
      )}

      <CalendarFooter />
    </Main>
  );
};

export default CalendarIndexPage;
