import PropTypes from 'prop-types';
import map from 'lodash/map';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import EventListing from './event-listing';

const useStyles = makeStyles((theme) => ({
  list: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  listItem: {
    margin: theme.spacing(2, 0),
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
    '&:first-child': {
      marginTop: 0,
    },
  },
}));

const EventList = ({ events, emptyMessage, group, className, ...rest }) => {
  const classes = useStyles();

  if (!events || !events.length) {
    return <p className={className}>{emptyMessage}</p>;
  }

  return (
    <ul className={clsx(classes.list, className)}>
      {map(events, (event) => {
        return (
          <li className={classes.listItem} key={event.id}>
            <EventListing
              event={event}
              group={group}
              {...rest}
            />
          </li>
        );
      })}
    </ul>
  );
};

EventList.propTypes = {
  events: PropTypes.array,
  group: PropTypes.object,
  emptyMessage: PropTypes.string,
  className: PropTypes.string,
};

EventList.defaultProps = {
  emptyMessage: 'No upcoming events',
};

export default EventList;
