import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const LoadMore = ({ onClick, loading, link }) => {
  const classes = useStyles();

  return (
    <Button
      href={link}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className={classes.button}
      variant="outlined"
      color="primary"
      disabled={loading}
    >
      {loading ? 'Loading...' : 'Load More'}
    </Button>
  );
};

LoadMore.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  link: PropTypes.string
};

export default LoadMore;
